<template>
  <div class="logout">
    <v-card>
    <v-toolbar
        color="primary"
        dark
    >Loged Out</v-toolbar>
    <v-card-text>
        <div class="text-h6 pa-12">이용해 주셔서 감사합니다.</div>
    </v-card-text>
    <v-card-actions class="justify-end">
        <v-btn
        text
        to="/"
        >닫기</v-btn>              
        <v-btn
        text
        to="/login"
        >재로그인</v-btn>
    </v-card-actions>
    </v-card>
  </div>
</template>

<script> 
import axios from 'axios';
import {mapState, mapActions, mapMutations} from 'vuex';

// const SERVER_URL = process.env.VUE_APP_SERVER_URL

export default {
  name: 'Logout',
  data: function () {
    return {
      credentials : {
        username : null,
        password : null,
      }
    }
  },

  computed: {
    ...mapState(['isLogout', 'isLogin', 'user']) 
  },

  created() {
      this.logout()
  },

  methods: {
    ...mapMutations(['setLogin', 'setLogout', 'setUser']),
    
    logout() {
      // Vueex State 삭제
      this.setUser({'user': null})
      this.setLogin({'isLogin': false})
      this.setLogout({'isLogout': true})
      // localStorage 삭제
      localStorage.removeItem('jwt')
      // Axios Header JWT Token 삭제
      axios.defaults.headers.common['Authorization'] = null
      // Home Pahe로 이동
      // this.$router.push({ name : 'HelloTony'})
    },
    
  }
}
</script>


<style>
.logout{
   padding: 10px 10px;
   margin-top: 140px;
}
</style>
